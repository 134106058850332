import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Pagination, PaginationProps, Select } from "antd";
import { pageSizeConfigs } from "config/commonConfig";
import { usePromptText } from "./accessibility/PromptStore";

const Paginator: React.FC<{
  currentPageSize: number;
  count: number;
  setCurrentPageSize: Function;
  setCurrentPage: Function;
  getShowingDataString: Function;
  paginationRender: PaginationProps["itemRender"];
  currentPage: number;
}> = ({
  currentPageSize,
  count,
  setCurrentPageSize,
  setCurrentPage,
  getShowingDataString,
  paginationRender,
  currentPage,
}) => {
  const [dataString, setDataString] = useState(getShowingDataString());
  const { setPromptText, setPromptType } = usePromptText();
  const updateDataString = useCallback(() => {
    setDataString(getShowingDataString());
    if (count >0) {
      setPromptType("polite");
      setPromptText(dataString);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dataString, setPromptText, setPromptType, count])
  useEffect(() => {
    updateDataString();
  }, [updateDataString]);
  return (
    <div className="pagerWrap">
      {count > 0 && (
        <div className="pageOption">
          <span>Page size:</span>
            <Select
              value={currentPageSize}
              style={{ width: 80 }}
              onChange={(value: number) => {
                setCurrentPageSize(value);
              }}
              options={pageSizeConfigs.OPTIONS}
            />
          {<span>{dataString}</span>}
        </div>
      )}
      <Pagination
        pageSize={currentPageSize}
        total={count}
        itemRender={paginationRender}
        showSizeChanger={false}
        onChange={(page) => {
          setCurrentPage(page);
        }}
        current={currentPage}
        hideOnSinglePage={true}
      />
    </div>
  );
};

export default Paginator;
