import { IPQuestion } from "modules/organization/models/interface";
import { EquationParser, ILogicalExpression } from "./equationParser";

interface IAnswerData {
    questionVariable: string;
    ansResponse:      IAnsResponse[];
}

interface IAnsResponse {
    [key:string]: any
}

interface IQuestionResponseVariable{
    questionVariable: string;
    response:         string[];
}

interface IAllEquationSet{
    name: string;
    equation: string;
    result: 0 | 1 | null;
}


class CalculateEquation extends EquationParser {
    private questions: IPQuestion[];
    private answers: IAnswerData[];
    private questionResponseVariables: IQuestionResponseVariable[];
    private allEquations: IAllEquationSet[];

    constructor(equation: string, questionData: IPQuestion[], answerData: IAnswerData[], allEquationSet: IAllEquationSet[]){
        super(equation);
        this.questions = questionData;
        this.answers = answerData;
        this.allEquations = allEquationSet;

        this.questionResponseVariables = questionData.map(eachQuestion => {
            return {
                questionVariable: eachQuestion.question_variable,
                response: eachQuestion.responses.map(eachRes => eachRes.response_variable)
            }
        });
    }

    private isQuestionVariablePresentInQuestion(questionVar:string){
        if(this.questionResponseVariables.find(each => {
            return each.questionVariable === questionVar;
        })){
            return true;
        }
        return false;
    }

    private isResponseVariablePresentInQuestion(responseVar:string){
        if(this.questionResponseVariables.find(each => {
            if(each.response.find(eachRes => eachRes === responseVar)){
                return true;
            }
            return false;
        })){
            return true;
        }
        return false;
    }

    private responseVariableHasInAnswer(responseVar: string){
        if(!responseVar){
            throw new Error('Response variable not present');
        }
    
        if(!this.isResponseVariablePresentInQuestion(responseVar)){
            throw new Error(`${responseVar} is not found in question set`);
        }
        
        const foundAnswer = this.answers.find(eachAns => {
            const findOption = eachAns.ansResponse.find(each => {
                if(each[responseVar]){
                    return true;
                }
                return false;
            })
            if(findOption){
                return true;
            }
            return false;
        });
    
        if(foundAnswer){
            return true;
        }
        return false;
    }

    private operatorAND(variables: string[] | 1[] | 0[]){
        const allVar = [...variables];
    
        if(!allVar.length ){
            return 0;
        }
        allVar.forEach((eachVar, index) => {
            if(typeof eachVar === 'string'){
                if(this.responseVariableHasInAnswer(eachVar)){
                    allVar[index] = 1;
                }
                else{
                    allVar[index] = 0;
                }
            }
        });
        if(allVar.length === allVar.filter(each => each === 1).length){
            return 1;
        }
        return 0;
    }

    private operatorOR(variables: string[] | 1[] | 0[]){
        const allVar = [...variables];
        if(!allVar.length ){
            return 0;
        }
    
        allVar.forEach((eachVar, index) => {
            if(typeof eachVar === 'string'){
                if(this.responseVariableHasInAnswer(eachVar)){
                    allVar[index] = 1;
                }
                else{
                    allVar[index] = 0;
                }
                
            }
        });
    
        if(allVar.find(each => each === 1)){
            return 1;
        }
        return 0;
    }

    private operatorQAND(questionVariables:string[] ){
        if(!questionVariables.length || questionVariables.length > 1){
            throw new Error('QAND only take one question variable');
        }
        if(!this.isQuestionVariablePresentInQuestion(questionVariables[0])){
            throw new Error(`${questionVariables[0]} variable not present in question list`);
        }
    
        const findQuestion = this.questionResponseVariables.find(each => each.questionVariable === questionVariables[0]);
    
        if(findQuestion){
            return this.operatorAND(findQuestion.response);
        }
        return 0;
    }

    private operatorQOR(questionVariables:string[]){
        if(!questionVariables.length || questionVariables.length > 1){
            throw new Error('QOR only take one question variable');
        }
        if(!this.isQuestionVariablePresentInQuestion(questionVariables[0])){
            throw new Error(`${questionVariables[0]} variable not present in question list`);
        }
    
        const findQuestion = this.questionResponseVariables.find(each => each.questionVariable === questionVariables[0]);
    
        if(findQuestion){
            return this.operatorOR(findQuestion.response);
        }
        return 0;
    }

    private operatorEQUAL(variables:string[]){
        if(!variables.length || variables.length !== 2){
            throw new Error('EQUAL only take two variables');
        }
        const responseVariable = variables[0];
        const compareString = variables[1];
        if(!this.responseVariableHasInAnswer(responseVariable)){
            return 0;
        }
    
        const foundAnswer = this.answers.find(eachAns => {
            if(eachAns.ansResponse.find(eachRes =>{
                if(eachRes[responseVariable] && eachRes[responseVariable].toLowerCase() === compareString.toLowerCase()){
                    return true;
                }
                return false;
            })){
                return true;
            }
            return false;
        });
    
        if(foundAnswer){
            return 1;
        }
        return 0;
    }

    private operatorNOTEQUAL(variables: string[]){
        if(!variables.length || variables.length !== 2){
            throw new Error('NOTEQUAL only take two variables');
        }
        const responseVariable = variables[0];
        const compareString = variables[1];
    
        if(!this.responseVariableHasInAnswer(responseVariable)){
            return 0;
        }
    
        const foundAnswer = this.answers.find(eachAns => {
            if(eachAns.ansResponse.find(eachRes =>{
                if(eachRes[responseVariable] && eachRes[responseVariable].toLowerCase() !== compareString.toLowerCase()){
                    return true;
                }
                return false;
            })){
                return true;
            }
            return false;
            
        });
    
        if(foundAnswer){
            return 1;
        }
        return 0;
    }

    private operatorCOMPARE(variables: string[]){
        if(!variables.length || variables.length !== 3){
            throw new Error('COMPARE take three parameters');
        }
        const responseVariable = variables[0];
        const operator = variables[1];
        const compareString = variables[2];
    
        const compareOperators = ['=', '!=', '<', '>', '>=', '<='];
    
        if(!compareOperators.includes(operator)){
            throw new Error('COMPARE operator must be compare symbol '+ compareOperators.join(', '));
        }

        const ans = this.answers;
    
        function findAnswer(){
    
            let answerText:string | null = null;
    
            ans.forEach(eachAns => {
                eachAns.ansResponse.forEach(eachRes =>{
                    if(eachRes[responseVariable] && !answerText){
                        answerText = eachRes[responseVariable];
                    }
                })
            });
    
            return answerText;
    
        }
    
        const _ans = findAnswer();
        const _ansNum = Number(_ans);
        const _compareStringNum = Number(compareString);
    
        if(operator === '='){
            return this.operatorEQUAL([responseVariable, compareString]);
        }
        else if(operator === '!='){
            return this.operatorNOTEQUAL([responseVariable, compareString]);
        }
        else if(operator === '<'){
            if(!_ans || isNaN(_ansNum) ||isNaN(_compareStringNum)){
                return 0;
            }
            
            return _ansNum < _compareStringNum ? 1 : 0;
            
        }
        else if(operator === '>'){
            if(!_ans || isNaN(_ansNum) ||isNaN(_compareStringNum)){
                return 0;
            }
    
            return _ansNum > _compareStringNum ? 1 : 0;
        }
        else if(operator === '>='){
            if(!_ans || isNaN(_ansNum) ||isNaN(_compareStringNum)){
                return 0;
            }
    
            return _ansNum >= _compareStringNum ? 1 : 0;
        }
        else if(operator === '<='){
            if(!_ans || isNaN(_ansNum) ||isNaN(_compareStringNum)){
                return 0;
            }
    
            return _ansNum <= _compareStringNum ? 1 : 0;
        }
    
        return 0;
    }

    private operatorQHASANSWER(questionVariables:string[]){
        if(!questionVariables.length || questionVariables.length > 1){
            throw new Error('QHASANSWER only take one question variable');
        }
        if(!this.isQuestionVariablePresentInQuestion(questionVariables[0])){
            throw new Error(`${questionVariables[0]} variable not present in question list`);
        }
    
        const findQuestion = this.questionResponseVariables.find(each => each.questionVariable === questionVariables[0]);
    
        if(!findQuestion){
            throw new Error(`${questionVariables[0]} not exist`);
        }
    
        const findAnswer = this.answers.find(each => each.questionVariable === questionVariables[0]);
    
        if(findAnswer){
            return 1;
        }
    
        return 0;
    }

    private operatorQHASNOTANSWER(questionVariables: string[]){
        if(!questionVariables.length || questionVariables.length > 1){
            throw new Error('QHASNOTANSWER only take one question variable');
        }
        if(!this.isQuestionVariablePresentInQuestion(questionVariables[0])){
            throw new Error(`${questionVariables[0]} variable not present in question list`);
        }
    
        const findQuestion = this.questionResponseVariables.find(each => each.questionVariable === questionVariables[0]);
    
        if(!findQuestion){
            throw new Error(`${questionVariables[0]} not exist`);
        }
    
        const findAnswer = this.answers.find(each => each.questionVariable === questionVariables[0]);
    
        if(!findAnswer){
            return 1;
        }
    
        return 0;
    }


    operatorIMPORT(equation: string[]){
        if(!equation.length || equation.length !== 1){
            throw new Error('IMPORT only take one equation');
        }
    
        const currentEquation = equation[0];
    
        const findEquation = this.allEquations.find(each => each.name === currentEquation);
    
        if(!findEquation){
            throw new Error(currentEquation + ' not found');
        }

        if(findEquation.result === null){
            throw new Error(currentEquation + ' has no result');
        }
        
        return findEquation.result ? 1 : 0;
    }

    private calculateEachOperator(operatorName: string, argArr: string[]){
        const _argArr = [...[...argArr].filter(each => each !== 'CONVERTED-OPERATOR')];
    
        const _operatorName = operatorName.toUpperCase();
        
        if(_operatorName === 'AND'){
            return this.operatorAND(_argArr);
        }
        else if(_operatorName === 'OR'){
            return this.operatorOR(_argArr);
        }
        else if(_operatorName === 'QAND'){
            return this.operatorQAND(_argArr);
        }
        else if(_operatorName === 'QOR'){
            return this.operatorQOR(_argArr);
        }
        else if(_operatorName === 'EQUAL'){
            return this.operatorEQUAL(_argArr);
        }
        else if(_operatorName === 'NOTEQUAL'){
            return this.operatorNOTEQUAL(_argArr);
        }
        else if(_operatorName === 'COMPARE'){
            return this.operatorCOMPARE(_argArr);
        }
        else if(_operatorName === 'QHASANSWER'){
            return this.operatorQHASANSWER(_argArr);
        }
        else if(_operatorName === 'QHASNOTANSWER'){
            return this.operatorQHASNOTANSWER(_argArr);
        }
        else if(_operatorName === 'IMPORT'){
            return this.operatorIMPORT(_argArr);
        }
        return 0;
    }

    private iterateArray(arr: ILogicalExpression) {
        arr.forEach((element, index) => {
            if (Array.isArray(element)) {
                if(!element.some(Array.isArray)){
                    const operatorName = arr[index-1];
                    const params = arr[index];
                    //console.log(arr[index-1] ?? 'Not found');
                    //console.log(arr[index]);
                    arr[index-1] = 'CONVERTED-OPERATOR';
                    arr[index] = this.calculateEachOperator(operatorName as string , params as string[]);
                }
                else{
                    this.iterateArray(element); // Recursively call iterateArray for nested arrays
                }
                
            } else {
               // console.log(element); // Print the element if it's not an array
            }
        });
        return arr;
    }
    
    private calculate(arr: ILogicalExpression): string | number | ILogicalExpression {
        const newArr = this.iterateArray(arr);
        if(!newArr.every((each: any) => typeof each === 'string')){
            console.log(newArr);
            if(newArr[0] === 'CONVERTED-OPERATOR'){
                return newArr[1];
            }
            return this.calculate(newArr);
        }
        return 0;
    }


    public getResult(){
        const hasEquationError = this.getHasError();
        if(hasEquationError){
            throw new Error(`Equation has a syntax error at index ${hasEquationError}: ${this.equationRaw}`);
        }

        return this.calculate(this.equationStack);

    }


}

export default CalculateEquation;