import { notification } from "antd";
import { Fragment, useCallback, useEffect } from "react";

import {
  LoadingOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { useRbac } from "auth/rbac/rbac";
import { Socket, io } from "socket.io-client";
import { useSiteStore } from "../store/siteStore";

export interface NamespaceSpecificServerToClientEvents {
  "datapipeline:started": (data: ISocketEventData) => void;
  "datapipeline:success": (data: ISocketEventData) => void;
  "datapipeline:error": (data: ISocketEventData) => void;
}

export interface ISocketEventData {
  project_id: number;
  publish_id: number;
  site_key: string;
  org_key: string;
  user_id: string;
  project_title: string;
  site_title: string;
}

const OrgNotification: React.FC<{}> = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const { organizationKey } = useOrganizationRoutes();
  const { userDetails, hasPermissions } = useRbac();
  const {
    refreshSiteProjectList,
    currentPage,
    currentPageSize,
    currentSortColumn,
    currentSortOrder,
    search,
    published,
    isPreviewOnly,
    site,
  } = useSiteStore();

  const pipelineStarted = useCallback(
    (data: ISocketEventData) => {
      if (userDetails?.id === data.user_id) {
        refreshSiteProjectList({
          page_index: currentPage,
          page_size: currentPageSize,
          sort_column: currentSortColumn,
          sort_order: currentSortOrder,
          search: search === "" ? null : search,
          published: published,
          is_preview_only: isPreviewOnly,
          site_id: site,
        }, true).catch(console.error);
        api.open({
          key: `${data.user_id}_${data.publish_id}`,
          message: "Publishing Project",
          icon: <LoadingOutlined style={{ color: "#006eff" }} />,
          description: (
            <p>
              <strong>{data.project_title}</strong> is publishing to{" "}
              <strong>{data.site_title}</strong>
            </p>
          ),
          duration: 0,
        });
      }
    },
    [api, userDetails?.id]
  );

  const pipelineSuccess = useCallback(
    (data: ISocketEventData) => {
      if (userDetails?.id === data.user_id) {
        refreshSiteProjectList({
          page_index: currentPage,
          page_size: currentPageSize,
          sort_column: currentSortColumn,
          sort_order: currentSortOrder,
          search: search === "" ? null : search,
          published: published,
          is_preview_only: isPreviewOnly,
          site_id: site,
        }, true).catch(console.error);
        api.open({
          key: `${data.user_id}_${data.publish_id}`,
          message: "Project published successfully",
          icon: <CheckCircleFilled style={{ color: "#23d100" }} />,
          description: (
            <p>
              <strong>{data.project_title}</strong> is published successfully to{" "}
              <strong>{data.site_title}</strong>
            </p>
          ),
          duration: 0,
        });
      }
    },
    [api, userDetails?.id]
  );

  const pipelineError = useCallback(
    (data: ISocketEventData) => {
      console.error(data, "error");
      if (userDetails?.id === data.user_id) {
        refreshSiteProjectList({
          page_index: currentPage,
          page_size: currentPageSize,
          sort_column: currentSortColumn,
          sort_order: currentSortOrder,
          search: search === "" ? null : search,
          published: published,
          is_preview_only: isPreviewOnly,
          site_id: site,
        }, true).catch(console.error);
        api.open({
          key: `${data.user_id}_${data.publish_id}`,
          message: "Project publish failed",
          icon: <CloseCircleFilled style={{ color: "#A41E35" }} />,
          description: (
            <p>
              <strong>{data.project_title}</strong> publishing to{" "}
              <strong>{data.site_title}</strong> failed
            </p>
          ),
          duration: 0,
        });
      }
    },
    [api, userDetails?.id]
  );

  useEffect(() => {
    const checkSitePermission = hasPermissions([
      ERbacPermissions.ORG_SITE_LIST_ALL,
      ERbacPermissions.ORG_GROUP_LIST_ASSIGNED,
    ]);
    if (
      organizationKey &&
      ["daipl", "cphlr"].includes(organizationKey) &&
      checkSitePermission
    ) {
      const socket: Socket<NamespaceSpecificServerToClientEvents> = io(
        `${process.env.REACT_APP_SOCKET_URL}/${organizationKey}`
      );

      socket.on("connect", () =>
        console.info("Socket connected successfully!")
      );
      socket.on("connect_error", (error) =>
        console.error({ "Socket error!": error })
      );
      socket.on("disconnect", () =>
        console.info("Socket disconnected successfully!")
      );

      //pipeline started
      socket.on("datapipeline:started", pipelineStarted);
      socket.on("datapipeline:success", pipelineSuccess);
      socket.on("datapipeline:error", pipelineError);
      return () => {
        socket.disconnect();
      };
    }
  }, [organizationKey]);

  return <Fragment>{contextHolder}</Fragment>;
};

export default OrgNotification;
