export enum ERbacPermissions {
    /** For Organization */

    //Manage User
    ORG_MANAGE_USER_LIST = 'user:list',
    ORG_MANAGE_USER_ADD = 'user:add',
    ORG_MANAGE_USER_EDIT = 'user:edit',
    ORG_MANAGE_USER_VIEW = 'user:view',
    ORG_MANAGE_USER_DELETE = 'user:delete',

    //Dashboard
    ORG_DASHBOARD_RECENT_PROJECT_LIST_WIDGET = 'project:recent',
    ORG_DASHBOARD_PROJECT_CHART_WIDGET = 'project:chart',

    // Project
    ORG_PROJECT_LIST_ALL = 'project:list:all',
    ORG_PROJECT_LIST_ASSIGNED = 'project:list:assigned',
    ORG_PROJECT_LIST_SITE = 'project:list:site',
    ORG_PROJECT_CREATE = 'project:create',
    ORG_PROJECT_EDIT = 'project:edit',
    ORG_PROJECT_CLONE = 'project:copy',
    ORG_PROJECT_PUBLISH = 'project:publish',
    ORG_PROJECT_CODING_REVIEW = 'project:codeReview',
    ORG_PROJECT_ERROR_SAMPLING = 'project:errorSampling',
    ORG_PROJECT_DELETE = 'project:delete',
    ORG_PROJECT_TEAM_MODIFY_PERMISSION = 'project:team:modifyPermission',
    ORG_PROJECT_SHOW_MANAGE_GROUP_MEMBER = 'project:create:edit:manage:team:view',
    ORG_PROJECT_DIRECT_PERMISSIONS = 'project:team:modifyPermission:directPermissions',
    ORG_PROJECT_CREATE_RECORD = 'project:record:add',
    ORG_PROJECT_EDIT_RECORD = 'project:record:edit',
    ORG_PROJECT_QUESTION_VIEW = 'project:question:view',
    ORG_PROJECT_QUESTION_ADD = 'project:question:add',
    ORG_PROJECT_QUESTION_EDIT = 'project:question:edit',
    ORG_PROJECT_QUESTION_DELETE = 'project:question:delete',
    ORG_PROJECT_RECORD_HIDE = 'project:record:hide',
    ORG_PROJECT_RECORD_SHOW_ROLE_FILTER = 'project:list:showRoleFilter',
    ORG_PROJECT_RECORD_SHOW_MEMBER_FILTER = 'project:list:showMemberFilter',


    ORG_PROJECT_RECORD_ADD_SOURCE = 'project:record:source:create',
    ORG_PROJECT_RECORD_EDIT_SOURCE = 'project:record:source:edit',
    ORG_PROJECT_RECORD_DELETE_SOURCE = 'project:record:source:delete',
    ORG_PROJECT_RECORD_SOURCE_ADD_EDIT_DELETE_CITATION = 'project:record:source:citation',
    ORG_PROJECT_RECORD_MARK_FINISHED = 'project:record:markFinished',
    ORG_PROJECT_RECORD_ANSWER = 'project:record:answer',
    ORG_PROJECT_RECORD_SOURCE_FILE_ATTACH = "project:record:source:fileAttach",
    ORG_PROJECT_RECORD_AMEND_SOURCE = "project:record:source:amend",
    ORG_PROJECT_RECORD_AMEND_RECORD = "project:record:amend",
    ORG_PROJECT_RECORD_CLONE_RECORD = "project:record:clone",
    ORG_PROJECT_RECORD_RESTORE_DELETED_RECORD = "project:record:restoreDeleted",

    //Group
    ORG_GROUP_LIST_ALL = "group:list:all",
    ORG_GROUP_LIST_ASSIGNED = "group:list:assigned",
    ORG_GROUP_EDIT = "group:edit",
    ORG_GROUP_CREATE = "group:create",

    /** For Super User */
    APP_ADMIN_DASHBOARD_EDIT = "app:settings:edit",
    APP_ADMIN_DASHBOARD_MANAGE_ADDONS = "app:feature:extension", 

    /** For User */
    USER_PROFILE = "user:profile",

    /** For Sites */
    ORG_SITE_LIST_ALL = "site:list:all",
    ORG_SITE_LIST_ASSIGNED = "site:list:assigned",
    ORG_SITE_CREATE = "site:create",
    ORG_SITE_EDIT = "site:edit",
    ORG_SITE_MANAGE_USER = "site:manageUser",
    ORG_SITE_DELETE = "site:delete",
    ORG_SITE_MANAGE_TOKEN = "site:manageToken",

    ORG_MIGRATION_VIEW = "migration:view",

    /** For Ranking */
    ORG_RANKING_SETUP = "ranking:setup",
    ORG_RANKING_SETUP_CRUD = "ranking:template:manage"
}