import { IApiBaseResponse } from "models/interface";
import {
  IRequestSiteProjectListData,
  IRequestSiteUserListData,
  IRequestUnPublishProject,
  IResponseAddSiteUser,
  IResponseCreateSite,
  IResponseProjectSiteListData,
  IResponseSiteProjectListData,
  IResponseSiteUserListData,
} from "../models/interface";
import { ApiService } from "modules/shared/services";
import { RoutesEnum } from "models/enums";
import { URLService } from './url.service';

export class SiteService {
  public async getSiteProjectListData(params: IRequestSiteProjectListData) {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<IResponseSiteProjectListData>
    >(RoutesEnum.ORG_SITE_GET_SITE_PROJECTS, { params });
  }

  public async getSiteUserListData(params: IRequestSiteUserListData) {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<IResponseSiteUserListData>
    >(RoutesEnum.ORG_SITE_GET_SITE_USER_LIST, { params });
  }

  public async getSiteListData() {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<IResponseProjectSiteListData>
    >(RoutesEnum.ORG_SITE_GET_SITE_LIST, {});
  }

  public async getSiteDetailsData(params: any) {
    const updatedURL = RoutesEnum.ORG_SITE_GET_SITE_DETAILS.replace(
      "{site_id}",
      params.site_id.toString()
    );
    return (await new ApiService().privateClient()).get<IApiBaseResponse<any>>(
      updatedURL,
      { params }
    );
  }

  public postCreateNewSite = async (site_title: string, site_url: string) => {
    return (await new ApiService().privateClient()).post<
      IApiBaseResponse<IResponseCreateSite>
    >(RoutesEnum.ORG_SITE_POST_CREATE_SITE, {
      site_title,
      site_url,
    });
  };

  public postEditSite = async (
    site_id: number,
    site_title: string,
    site_url: string,
    enable_law_atlas_iframe: boolean
  ) => {
    return (await new ApiService().privateClient()).post<
      IApiBaseResponse<IResponseCreateSite>
    >(RoutesEnum.ORG_SITE_POST_EDIT_SITE, {
      site_id,
      site_title,
      site_url,
      enable_law_atlas_iframe,
    });
  };

  public async checkSiteUniqueTitle(params: any) {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<{ exists: boolean }>
    >(RoutesEnum.ORG_CHECK_SITE_UNIQUE_TITLE, { params });
  }

  public async getPreviewPasscode(preview_id: number) {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<{ passcode_details: {passcode: number | string} }>
    >(RoutesEnum.ORG_SITE_GET_SITE_PREVIEW_PASSCODE, { params:  {preview_id}});
  }

  public async updatePreviewPasscode(preview_id: number) {
    return (await new ApiService().privateClient()).post<
      IApiBaseResponse<{ passcode_details: {passcode: number | string} }>
    >(RoutesEnum.ORG_SITE_UPDATE_SITE_PREVIEW_PASSCODE, { preview_id });
  }
  public postAddSiteUser = async (
    site_id: number,
    // users: { id: string; role_id: string | null | undefined; role_alias: string | undefined } []
    users: any []
  ) => {
    return (await new ApiService().privateClient()).post<
      IApiBaseResponse<IResponseAddSiteUser>
    >(RoutesEnum.ORG_SITE_POST_SITE_USER, {
      site_id,
      users,
    });
  };

  public deleteSiteUser = async (
    site_id: number,
    delete_user_id: string
  ) => {
    return (await new ApiService().privateClient()).post<
      IApiBaseResponse<IResponseAddSiteUser>
    >(RoutesEnum.ORG_SITE_DELETE_SITE_USER, {
      site_id,
      delete_user_id,
    });
  };

  public async unPublishProject(payload: IRequestUnPublishProject, projectSlug: string) {
    const  { updatedURL } = new URLService({}, projectSlug).formatURL(RoutesEnum.ORG_PROJECT_UNPUBLISH);
    return (await new ApiService().privateClient()).post<IApiBaseResponse>(updatedURL, payload);
  }

  public async unLinkProject(payload: IRequestUnPublishProject, projectSlug: string) {
    const  { updatedURL } = new URLService({}, projectSlug).formatURL(RoutesEnum.ORG_PROJECT_UNLINK);
    return (await new ApiService().privateClient()).post<IApiBaseResponse>(updatedURL, payload);
  }

  public async deleteSite(data: Pick<IRequestUnPublishProject, 'site_id'>) {
    return (await new ApiService().privateClient()).delete<IApiBaseResponse>(RoutesEnum.ORG_SITE_POST_DELETE_SITE, { data });
  }

  public async datapipeline(data: any) {
    return (await new ApiService().privateClient()).post('organization/site/datapipeline', data);
  }

  public getSiteToken = async (site_id: number, site_key: string) => {
    return (await new ApiService().privateClient()).post<IApiBaseResponse>(RoutesEnum.ORG_SITE_GET_SITE_TOKEN, {
      site_id,
      site_key,
    });
  };
}
