import { create } from "zustand";
import { RecordsService } from "../services/records.service";
import { IHttpError } from "models/interface";
import { IAnswer, ICompleteRecordDetails, ISourceDetails, SubmitAnswer, ISourceCitationDetails } from "../models/interface/record.interface";
import { Key } from "antd/es/table/interface";

interface IRecordDetailsState {
  recordDetails: ICompleteRecordDetails | null;
  isMarkedFinished: boolean;
  isLoadingRecordDetails: boolean;
  error: IHttpError | null;
  sourceListError: IHttpError | null;
  sourceAccordion: ISourceDetails[];
  allCitationsOfRecord: ISourceCitationDetails[];
  goingToDeleteCitation: {citationId: number, lawId: number} | null;
  selectedQuestionId: number;
  expandedSourceKeys: number[];
  lastSaved: Date | null;
  answerList: IAnswer[];
  isSavingAnswer: boolean | null;
  answerSaveInprogress: number[];
  expandedCitationId: number | null;
  referenceWidth: number;
  currentHoveredCitation: number | null;
  unsavedSourceIds: number[];
  treeExpandedKeys: Key[];
  setReferenceWidth: (value: number) => void;
  getRecordDetails: (project_slug: string, record_id: number) => Promise<void>;
  clearRecordDetails: () => void;
  updateCitationAfterQuestionLink: (citationId: number, questionIds: number[]) => void;
  setSelectedQuestionId: (value: number) => void;
  setExpandedSourceKeys: (value: number[]) => void;
  setSourceAccordion: (sources: ISourceDetails[]) => void;
  refreshSourceList: (project_slug: string, record_id: number) => Promise<void>;
  setCreatedCitation: (citation: ISourceCitationDetails) => void;
  setMarkFinished: (value: boolean) => void;
  updateAnswerList: (value: IAnswer) => void;
  setIsSavingAnswer: (value: boolean | null) => void;
  setAnswerSaveInprogress: (value: number) => void;
  refreshLastSaved: (project_slug:string, record_id: number) => Promise<void>;
  setExpandedCitationId: (id?: number) => void;
  setCurrentHoveredCitation: (id?: number) => void;
  setUnsavedSourceIds: (value: number[]) => void;
  setGoingToDeleteCitation: (data: {citationId: number; lawId: number} | null) => void;
  deleteCitationFormAllCitationsOfRecord: (citationId:number) => void;
  checkCitationHasSourceHtml: (citationId:number) => boolean;
  updateExpandedKeys: (keys?: Key[]) => void;
  disableSuggestiveText: () => void;
}
const useStore = create<IRecordDetailsState>((set, get) => ({
  recordDetails: null,
  isLoadingRecordDetails: true,
  error: null,
  sourceAccordion: [],
  allCitationsOfRecord: [],
  goingToDeleteCitation: null,
  selectedQuestionId: 0,
  expandedSourceKeys: [],
  lastSaved: null,
  sourceListError: null,
  answerList: [],
  isMarkedFinished: false,
  isSavingAnswer: null,
  answerSaveInprogress: [],
  expandedCitationId: 0,
  referenceWidth: 0,
  currentHoveredCitation: null,
  unsavedSourceIds: [],
  treeExpandedKeys: [],

  getRecordDetails: async (project_slug, record_id) => {
    set(() => ({isLoadingRecordDetails: true}));
    try {
      const recordDetailsData = await new RecordsService().getRecordDetailsData(
        { project_slug, record_id }
      );
      const record_details = recordDetailsData.data.data.record_details;
      set(() => ({
        isLoadingRecordDetails: false,
        recordDetails: record_details,
        sourceAccordion: record_details.source_list ?? [],
        allCitationsOfRecord: collectAllCitationsFromSource(record_details.source_list ?? []),
        lastSaved: record_details.updated_at,
        expandedSourceKeys: record_details.source_list?.map(source => source.id) ?? [],
        answerList: record_details.answer_list ?? [],
        isMarkedFinished: record_details.is_marked_finished,
        error: null,
      }));
    } catch (error: any) {
      console.log(error);
      console.log(error?.code);
      set((state) => ({
        ...state,
        isLoadingRecordDetails: false,
        error: error
          ? {
            ...error,
            code: error?.code ?? 500,
            message: error?.message ?? "Something went wrong",
          }
          : null,
      }));
    }
  },
  clearRecordDetails: () => {
    set(() => ({
      isLoadingRecordDetails: true,
      sourceAccordion: [],
      allCitationsOfRecord: [],
      recordDetails: null,
      lastSaved: null,
      currentExpandedSource: [],
      isMarkedFinished: false,
      error: null,
      answerList: [],
      sourceListError: null,
      isSavingAnswer: null,
      answerSaveInProgress: [],
      currentHoveredCitation: null,
      treeExpandedKeys: [],
    }));
  },
  setReferenceWidth: (value) => {
    set(() => ({
      referenceWidth: value,
    }));
  },
  setSelectedQuestionId: (value) => {
    set(() => ({
      selectedQuestionId: value,
    }));
  },
  setExpandedSourceKeys: (value) => {
    set(() => ({
      expandedSourceKeys: value,
    }));
  },
  setSourceAccordion: (sources) => {
    set(() => ({
      sourceAccordion: [...sources],
      allCitationsOfRecord: collectAllCitationsFromSource(sources ?? []),
    }));
  },
  updateExpandedKeys: (keys) => {
    set(() => ({ treeExpandedKeys: keys ?? [] }))
  },
  setCreatedCitation: (citation) => {
    set((state) => {
      let _allCitationsOfRecord = [...state.allCitationsOfRecord];
      let _findCitation = _allCitationsOfRecord.findIndex(each => each.id === citation.id);
      if (_findCitation>=0) {
        _allCitationsOfRecord[_findCitation] = citation;
      }
      else {
        _allCitationsOfRecord = [..._allCitationsOfRecord, citation];
      }

      return {
        ...state,
        allCitationsOfRecord: _allCitationsOfRecord,
      }
    });
  },
  updateCitationAfterQuestionLink: (citationId: number, questionIds: number[]) => {
    set((state) => {
      const _allCitationsOfRecord = [...state.allCitationsOfRecord];
      const findCitation = _allCitationsOfRecord.find(each => each.id === citationId);
      if(findCitation){
        findCitation.linked_questions = questionIds;
      }
      return {
        ...state,
        allCitationsOfRecord: _allCitationsOfRecord,
      }
    });
  },
  refreshSourceList: async (project_slug, record_id) => {
    try {
      const { data } = await new RecordsService().getSourceList(record_id, project_slug);
      set(() => ({
        sourceAccordion: data.data.source_list ?? [],
        allCitationsOfRecord: collectAllCitationsFromSource(data.data.source_list ?? []),
        sourceListError: null,
      }));
    } catch (error: any) {
      console.log(error);
      console.log(error?.code);

      set((state) => ({
        ...state,
        sourceListError: error
          ? {
            ...error,
            code: error?.code ?? 500,
            message: error?.message ?? "Something went wrong",
          }
          : null,
      }));
    }
  },
  setMarkFinished: (value) => {
    set(() => ({
      isMarkedFinished: value,
    }));
  },
  updateAnswerList: (value) => {
    set((state) => {
      const tmpAnswersList = [...state.answerList];
      const answerIndex = tmpAnswersList.findIndex(each => each.question_id === value.question_id);
      if(answerIndex !== -1){
        tmpAnswersList[answerIndex] = {...value};
      } else {
        tmpAnswersList.push(value);
      }
      return {
        ...state,
        answerList: tmpAnswersList
      }
    });
  },
  setIsSavingAnswer: (value) => {
    set(() => ({
      isSavingAnswer: value,
    }));
  },
  setAnswerSaveInprogress: (value) => {
    set((state) => {
      if (state.answerSaveInprogress.includes(value)) {
        return {
          ...state,
          answerSaveInprogress: state.answerSaveInprogress.filter(id => id !== value)
        }
      }
      return {
        ...state,
        answerSaveInprogress: [...state.answerSaveInprogress, value]
      }
    });
  },
  refreshLastSaved: async (project_slug, record_id) => {
    try {
      const { data } = await new RecordsService().getRecordLastSaved(project_slug, record_id);
      set(() => ({
        lastSaved: data.data.last_saved
      }));
    } catch (error: any) {
      console.log(error);
      console.log(error?.code);
    }
  },
  setExpandedCitationId: (id?: number) => {
    set((state) => ({ expandedCitationId: state.expandedCitationId !== id ? id : 0 }));
  },
  setCurrentHoveredCitation: (id) => {
    set(() => ({ currentHoveredCitation: id ?? null }));
  },
  setUnsavedSourceIds: (value) => {
    set(() => ({
      unsavedSourceIds: value
    }));
  },
  setGoingToDeleteCitation: (data: {citationId: number; lawId: number} | null) => {
    set(() => ({
      goingToDeleteCitation: data
    }));
  },
  deleteCitationFormAllCitationsOfRecord: (citationId: number) => {
    set((state) => ({ allCitationsOfRecord: state.allCitationsOfRecord.filter(each => each.id !== citationId) }));
  },
  checkCitationHasSourceHtml: (citationId: number) => {
    const _sourceAccordion = get().sourceAccordion ?? [];
    const _allCitationsOfRecord = get().allCitationsOfRecord ?? [];
    const findRequestedCitation = _allCitationsOfRecord.find(eachCitation => eachCitation.id === citationId);

    if(_sourceAccordion.length && findRequestedCitation){
      if(findRequestedCitation.is_full_text){
        return true;
      }
      const findRequestedSource = _sourceAccordion.find(eachSource => eachSource.id === findRequestedCitation.law_id);
      return (findRequestedSource?.html_description?.includes("citationIdMark"+citationId.toString()) && findRequestedSource?.html_description?.includes("citationIdNode"+citationId.toString())) ?? false
    }
    return false;
  },
  disableSuggestiveText: () => {
    const tempRecordDetails = get().recordDetails;
    if (tempRecordDetails) {
      set(() => ({ recordDetails: { ...tempRecordDetails, is_suggestive_text_disabled: true } }));
    }
  }
}));

const collectAllCitationsFromSource = (allSource: ISourceDetails[]) => {

  let citations: ISourceCitationDetails[] = [];

  [...allSource].forEach(eachSource => {
    if (eachSource.citations?.length) {
      citations = [...citations, ...eachSource.citations.filter(eachCit => eachCit)]
    }
  })
  return citations;
}

const updateAnswer = (answer: IAnswer | undefined, res: SubmitAnswer, question_id: number) => {
  const replacedValue: SubmitAnswer = { ...res };

  /**
   * This block will run if new answer is added or answer list is empty and return the data
   * */
  if (!answer) {
    replacedValue.value = null;
    const updatedValue: IAnswer = {
      question_id,
      id: null,
      open_response: res.name === 'open_response' ? res.value : null,
      response_id: res.name === 'response_id' ? res.value : null,
      caution_note: res.name === 'caution_note' ? res.value : null
    }
    return { replacedValue, updatedValue }
  }

  /**
   * This block will run if an answer is edited or reset
   * */
  const updatedValue = {...answer}
  switch (res.name) {
    case 'caution_note':
      replacedValue.name = 'caution_note';
      replacedValue.value = answer.caution_note;
      updatedValue.caution_note = res.value;
      break;

    case 'open_response':
      replacedValue.name = 'open_response';
      replacedValue.value = answer.open_response;
      updatedValue.open_response = res.value as string
      break;

    case 'response_id':
      replacedValue.name = 'response_id';
      replacedValue.value = answer.response_id;
      updatedValue.response_id = res.value;
      break;

    default:
      break;
  }

  return { replacedValue, updatedValue }
}

export const doUpdateAnswerList = updateAnswer;
export const useProjectRecordDetailsStore = useStore;
